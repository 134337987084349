import React from "react";
import Lottie from "lottie-react";
import youtube from "../../assets/youtube_redes.json";

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.5],
      type: "play",
      frames: [0, 720],
    },
  ],
};

const Youtube = () => {
  return <Lottie animationData={youtube} interactivity={interactivity} />;
};

export default Youtube;
