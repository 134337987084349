import React from "react";
import { BiHomeAlt } from "react-icons/bi";
import { RiSpotifyLine } from "react-icons/ri";
import { RiFacebookBoxLine } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { RiYoutubeLine } from "react-icons/ri";
import { FaDeezer } from "react-icons/fa";

const HeaderSocials = () => {
  return (
    <div>
      <div className="header__socials">
        <a href="https://www.latrola.com.mx" target="_blank" rel="noreferrer">
          <BiHomeAlt />
        </a>
        <a
          href="https://open.spotify.com/artist/1L0tJ2aCQ32yIioYxpfZAT"
          target="_blank"
          rel="noreferrer"
        >
          <RiSpotifyLine />
        </a>
        <a
          href="https://www.facebook.com/latrola/"
          target="_blank"
          rel="noreferrer"
        >
          <RiFacebookBoxLine />
        </a>
        <a
          href="https://www.instagram.com/latrolaoficial/"
          target="_blank"
          rel="noreferrer"
        >
          <RiInstagramLine />
        </a>
        <a
          href="https://www.youtube.com/trolatv"
          target="_blank"
          rel="noreferrer"
        >
          <RiYoutubeLine />
        </a>
        <a
          href="https://www.deezer.com/es/artist/6372762"
          target="_blank"
          rel="noreferrer"
        >
          <FaDeezer />
        </a>
      </div>
    </div>
  );
};

export default HeaderSocials;
