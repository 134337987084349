import React from "react";
import "./footer.css";
import Loguito from "../../assets/loguito.png";
import { FaSpotify } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { FaDeezer } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <a href="https://latrola.com.mx/" className="footer__logo">
        <img src={Loguito} alt="logo" />
      </a>
      <ul className="permalinks">
        <li>
          <a href="/#">Home</a>
        </li>
        <li>
          <a href="#bio">Bio</a>
        </li>
        <li>
          <a href="#music">Discos</a>
        </li>
        <li>
          <a href="#video">Videos</a>
        </li>
        <li>
          <a href="#social">Redes</a>
        </li>
        <li>
          <a href="#contact">Contacto</a>
        </li>
      </ul>
      <div className="footer__socials">
        <a href="https://open.spotify.com/artist/1L0tJ2aCQ32yIioYxpfZAT">
          <FaSpotify />
        </a>
        <a href="https://www.facebook.com/latrola/">
          <FaFacebookSquare />
        </a>
        <a href="https://www.instagram.com/latrolaoficial/">
          <RiInstagramFill />
        </a>
        <a href="https://www.youtube.com/trolatv">
          <FaYoutube />
        </a>
        <a href="https://www.deezer.com/es/artist/6372762">
          <FaDeezer />
        </a>
      </div>

      <div className="footer__copyright">
        <small>
          &copy; La Trola, 2022. Diseñado por
          <a
            href="https://erick.freehall.com.mx"
            target="_blank"
            rel="noreferrer"
          >
            &nbsp;Erick Arellano.
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
