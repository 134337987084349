import React from "react";
import { useRef } from "react";
import "./contact.css";
import Contacttitle from "../../assets/contact_title.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { RiMessengerLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import emailjs from "emailjs-com";

/*Componente movimiento titulos*/
const contactVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 200,
    opacity: 0,
    scale: 1,
  },
};

/*Componente animacion*/
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_49ganab",
      "template_zj44u8t",
      form.current,
      "zOuElEgxg0jLsYsEF"
    );

    e.target
      .reset()

      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <section id="contact" className="contact">
      <div className="contact__container">
        <motion.div
          className="contact__title"
          variants={contactVariant}
          ref={ref}
          initial="hidden"
          animate={control}
        >
          <div className="contact__title-image">
            <img src={Contacttitle} alt="contact" />
          </div>
        </motion.div>

        <div className="contact__caja">
          <div className="contact__options">
            <a
              href="mailto:contacto@latrola.com.mx?subject=Informes sobre contratación"
              target="_blank"
              rel="noreferrer"
            >
              <article className="contact__option">
                <HiOutlineMail className="option-icon" />
                <h4>Email</h4>
              </article>
            </a>

            <a href="http://m.me/latrola" target="_blank" rel="noreferrer">
              <article className="contact__option">
                <RiMessengerLine className="option-icon" />
                <h4>Messenger</h4>
              </article>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=2214415030"
              target="_blank"
              rel="noreferrer"
            >
              <article className="contact__option">
                <FaWhatsapp className="option-icon" />
                <h4>WhatsApp</h4>
              </article>
            </a>
          </div>

          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre completo"
              required
            />
            <input type="text" name="email" placeholder="Email" required />
            <textarea
              name="mensaje"
              rows="6"
              placeholder="Tu mensaje"
              required
            ></textarea>
            <button type="submit" className="btn">
              ENVIAR
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
