import React from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import Bio from "./components/bio/Bio";
import Music from "./components/music/Music";
import Video from "./components/video/Video";
import Social from "./components/social/Social";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <div>
      <>
        <Header />
        <Nav />
        <Bio />
        <Music />
        <Video />
        <Social />
        <Contact />
        <Footer />
      </>
    </div>
  );
};

export default App;
