import React from "react";
import { useLottie } from "lottie-react";
import logo from "../../assets/logo.json";

const Logo = () => {
  const options = {
    animationData: logo,
    loop: false,
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};

export default Logo;
