import React from "react";
import "./bio.css";
import Botones from "./Botones";
import Biotitle from "../../assets/bio_title.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

/*Marquesina*/

const marqueeVariants = {
  visible: {
    x: [0, -1020],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 12,
        ease: "linear",
      },
    },
  },
};

/*Componente movimiento titulos*/
const bioVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 200,
    opacity: 0,
    scale: 1,
  },
};

/*Componente movimiento parrafo*/
const parVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { delay: 0.2, ease: "easeOut", duration: 0.7 },
  },
  hidden: {
    opacity: 0,
    scale: 0.98,
  },
};

/**Componente movimiento botones*/

const botVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.4, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 20,
    opacity: 0,
    scale: 1,
  },
};

/*Componente animacion*/
const Bio = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <section id="bio" className="bio">
      <div className="bio__container">
        <motion.div
          className="bio__title"
          variants={bioVariant}
          ref={ref}
          initial="hidden"
          animate={control}
        >
          <div className="bio__title-image">
            <img src={Biotitle} alt="Bio" />
          </div>
        </motion.div>

        <motion.div
          className="parrafo__bio"
          variants={parVariant}
          ref={ref}
          initial="hidden"
          animate={control}
        >
          La Trola es una banda de rock mexicana nacida a finales de la primer
          década del dos mil en Puebla, Puebla. Sus integrantes son el vocalista
          Arturo Muñoz el Carcará, el guitarrista Gustavo Espíndola, el bajista
          Erick Arellano, Carlos González en el piano y sintetizadores, y el
          baterista Gary Espíndola. El estilo musical de la banda fusiona el
          rock tradicional y el rock alternativo incluyendo elementos de otros
          géneros como el rap, pop rock, heavy metal, dance, punk, hip hop e
          indie rock. Aunque en algunos sitios son considerados sólo como Rock
          urbano.
        </motion.div>
        <div>
          <motion.div
            className="botones__bio"
            variants={botVariant}
            ref={ref}
            initial="hidden"
            animate={control}
          >
            <div className="botones">
              <Botones />
            </div>
          </motion.div>
        </div>

        <div className="bio__pleca">
          <motion.div
            className="track__bio"
            variants={marqueeVariants}
            animate={control}
          >
            <span className="marquee_bio">Carcará:</span>
            <span className="marquee_bio-v">Voz&Guitarra&nbsp;</span>
            <span className="marquee_bio">Gustavo:</span>
            <span className="marquee_bio-v">Guitarras&nbsp;</span>
            <span className="marquee_bio">Gary:</span>
            <span className="marquee_bio-v">Batería&voz&nbsp;</span>
            <span className="marquee_bio">Erick:</span>
            <span className="marquee_bio-v">Bajo&nbsp;</span>
            <span className="marquee_bio">Charly:</span>
            <span className="marquee_bio-v">Teclados&nbsp;</span>
            <span className="marquee_bio">Carcará:</span>
            <span className="marquee_bio-v">Voz&Guitarra&nbsp;</span>
            <span className="marquee_bio">Gustavo:</span>
            <span className="marquee_bio-v">Guitarras&nbsp;</span>
            <span className="marquee_bio">Gary:</span>
            <span className="marquee_bio-v">Batería&voz&nbsp;</span>
            <span className="marquee_bio">Erick:</span>
            <span className="marquee_bio-v">Bajo&nbsp;</span>
            <span className="marquee_bio">Charly:</span>
            <span className="marquee_bio-v">Teclados&nbsp;</span>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Bio;
