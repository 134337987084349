import React from "react";
import EPK from "../../assets/epk.pdf";
import RIDER from "../../assets/epk.pdf";

const Botones = () => {
  return (
    <div className="botones_bio">
      <a href={EPK} download className="btn">
        EPK.pdf
      </a>
      <a href={RIDER} download className="btn">
        RIDER.pdf
      </a>
    </div>
  );
};

export default Botones;
