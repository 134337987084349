import React from "react";
import "./music.css";
import Musictitle from "../../assets/discos_title.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { IoMusicalNotes } from "react-icons/io5";
import Trola from "../../assets/la_trola.jpg";
import Mortal from "../../assets/mortal.jpg";
import Inmortal from "../../assets/inmortal.jpg";

/*Marquesina*/

const marqueeVariants = {
  visible: {
    x: [0, -1020],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 25,
        ease: "linear",
      },
    },
  },
};

/*Componente movimiento titulos*/
const MusicVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 200,
    opacity: 0,
    scale: 1,
  },
};

/*Componente portada*/
const portrolaVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.2, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const pormortalVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.4, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const porinmortalVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.6, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const Music = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <section id="music" className="music">
      <div className="music__container">
        <motion.div
          className="music__title"
          variants={MusicVariant}
          ref={ref}
          initial="hidden"
          animate={control}
        >
          <div className="music__title-image">
            <img src={Musictitle} alt="Music" />
          </div>
        </motion.div>

        <div className="discos__container">
          <motion.div
            className="discos"
            variants={portrolaVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://open.spotify.com/album/3OqMdfdPY6bF2rSzr09WBE?si=n_qGEp02S_WrKDpFjs_RoA"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Trola} alt="La Trola" />
            </a>
          </motion.div>

          <motion.div
            className="discos"
            variants={pormortalVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://open.spotify.com/album/6IxUQzhxr29lVRZfBHsKmZ?si=Sp5eGGLrSBuQNv2taCdlDQ"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Mortal} alt="Mortal" />
            </a>
          </motion.div>

          <motion.div
            className="discos"
            variants={porinmortalVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://open.spotify.com/album/47BYCQKWNs4w8ejtRxXZOF?si=MKTV29ZCQ7SRR2_GPRbxfQ"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Inmortal} alt="Inmortal" />
            </a>
          </motion.div>
        </div>

        <div className="music__pleca">
          <motion.div
            className="track__discos"
            variants={marqueeVariants}
            animate={control}
          >
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/4hzameONGYIwZREnFzHFwx?si=560dad9b376b436a"
                target="_blank"
                rel="noreferrer"
              >
                Alivio
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/2VTo86QLxcEC5AJvQUdWJK?si=443a05895c114ccb"
                target="_blank"
                rel="noreferrer"
              >
                Tiro Tranquilizador
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/5Ru70h9Sc1KEnjFT2NhqxB?si=044a0f2359ef4a68"
                target="_blank"
                rel="noreferrer"
              >
                Flotar
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/2Sdts8s1nLj5dE5DEE8aSf?si=d0c1efe3508748c2"
                target="_blank"
                rel="noreferrer"
              >
                Sortilegio
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/2wNsfNK75oSVJ1whf2Lc9J?si=16ad632ac8714906"
                target="_blank"
                rel="noreferrer"
              >
                La Rola Fantasma
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              TOP10
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/1eSWZSeCtydxIz37A4pS9K?si=e535bb7534d640d8"
                target="_blank"
                rel="noreferrer"
              >
                Bailando
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/7GPUM3LvueIoSrAFWmISX9?si=01fd65caf49a41d6"
                target="_blank"
                rel="noreferrer"
              >
                Billete de Cien
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/3tOEADm4kEcLQu2CCba9Nb?si=9d70955ddd8848bb"
                target="_blank"
                rel="noreferrer"
              >
                Nada de Ti
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/1KAWfLMYshnNVzTztwCUyu?si=26707ff15e2543d8"
                target="_blank"
                rel="noreferrer"
              >
                El Grito del Alacrán
              </a>
              <IoMusicalNotes />
            </span>
            <span className="marquee__discos">
              <a
                href="https://open.spotify.com/track/0ousI2z3hGgB8V7KANg7f8?si=9fabfb48afe04ac9"
                target="_blank"
                rel="noreferrer"
              >
                El tibio Intelectual
              </a>
              <IoMusicalNotes />
            </span>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Music;
