import React from "react";
import Lottie from "lottie-react";
import instagram from "../../assets/instagram_redes.json";

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.5],
      type: "play",
      frames: [0, 720],
    },
  ],
};

const Instagram = () => {
  return <Lottie animationData={instagram} interactivity={interactivity} />;
};

export default Instagram;
