import React from "react";
import "./video.css";
import Videotitle from "../../assets/video_title.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

import Grito from "../../assets/video_01.jpg";
import Billete from "../../assets/video_02.jpg";
import Gas from "../../assets/video_03.jpg";
import Flotar from "../../assets/video_04.jpg";
import Carro from "../../assets/video_05.jpg";
import Nacion from "../../assets/video_06.jpg";

/*Marquesina*/

const marqueevVariants = {
  visible: {
    x: [0, -4970],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "linear",
        duration: 80,
        ease: "linear",
      },
    },
  },
};

/*Componente movimiento titulos*/
const VideoVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 100,
    opacity: 0,
    scale: 1,
  },
};

/*Componente portada*/
const gritoVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.1, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const billeteVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.2, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const gasVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.3, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const flotarVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.4, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const carroVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.5, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const nacionVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.6, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const Video = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <section id="video" className="video">
      <div className="video__container">
        <motion.div
          className="video__title"
          variants={VideoVariant}
          ref={ref}
          initial="hidden"
          animate={control}
        >
          <div className="video__title-image">
            <img src={Videotitle} alt="Video" />
          </div>
        </motion.div>

        <div className="videos__container">
          <motion.div
            className="videos"
            variants={gritoVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://youtu.be/MpHkKvoiHh4"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Grito} alt="Brito" />
            </a>
          </motion.div>

          <motion.div
            className="videos"
            variants={billeteVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://youtu.be/qFybi-uwB_Q"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Billete} alt="Billete" />
            </a>
          </motion.div>

          <motion.div
            className="videos"
            variants={gasVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://youtu.be/rBoanZo_b0U"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Gas} alt="Gas" />
            </a>
          </motion.div>

          <motion.div
            className="videos"
            variants={flotarVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://youtu.be/fagnT7e8HDA"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Flotar} alt="Flotar" />
            </a>
          </motion.div>

          <motion.div
            className="videoshome"
            variants={carroVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://vip.latrola.com.mx/videos/carroneros.mp4"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Carro} alt="Carroñeros" />
            </a>
          </motion.div>

          <motion.div
            className="videoshome"
            variants={nacionVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://vip.latrola.com.mx/videos/nacion.mp4"
              className="discos-image"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Nacion} alt="Nación" />
            </a>
          </motion.div>
        </div>

        <div className="video__pleca">
          <motion.div
            className="track__video"
            variants={marqueevVariants}
            animate={control}
          >
            <span className="marquee__video-v">
              <FaQuoteLeft />
            </span>
            <span className="marquee__video">
              &nbsp;Del Valhalla naufragué hasta Tenochtitlan, me sacrificaron y
              desperté en el Mictlán: allí sólo entran guerreros no un macehual
              del barrio.&nbsp;
            </span>
            <span className="marquee__video-v">
              <FaQuoteRight />
            </span>
            <span className="marquee__video-v2">
              &nbsp;Inmortal&nbsp;&nbsp;&nbsp;&nbsp;
            </span>

            <span className="marquee__video-v">
              <FaQuoteLeft />
            </span>
            <span className="marquee__video">
              &nbsp;¡Qué va, aquí no hay ningún puerto, es la ciudad! Inundada
              de mentira y vanidad. Demacrada, por tranzas del gobierno y la
              muerte en general.&nbsp;
            </span>
            <span className="marquee__video-v">
              <FaQuoteRight />
            </span>
            <span className="marquee__video-v2">
              &nbsp;&nbsp;La Rola Fantasma&nbsp;&nbsp;&nbsp;&nbsp;
            </span>

            <span className="marquee__video-v">
              <FaQuoteLeft />
            </span>
            <span className="marquee__video">
              &nbsp;Para ti solo soy un indígena agresor que vive obsesionado
              del rencor, para ti sólo soy una latina seducción que viene, baila
              cumbia y reguetón.&nbsp;
            </span>
            <span className="marquee__video-v">
              <FaQuoteRight />
            </span>
            <span className="marquee__video-v2">
              &nbsp;&nbsp;Barajas&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Video;
