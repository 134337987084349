import React from "react";
import "./social.css";
import Socialtitle from "../../assets/redes_title.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { GrFacebook } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";

import Spotify from "./Spotify";
import Youtube from "./Youtube";
import Instagram from "./Instagram";
import Facebook from "./Facebook";

/*Marquesina*/

const marqueeVariants = {
  visible: {
    x: [0, -1020],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "mirror",
        duration: 20,
        ease: "linear",
      },
    },
  },
};

/*Componente movimiento titulos*/
const SocialVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.1, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 200,
    opacity: 0,
    scale: 1,
  },
};

/*Componente portada*/
const spotifyVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.1, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const youtubeVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.3, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const facebookVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.5, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const instagramVariant = {
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay: 0.7, type: "spring", bounce: 0.7, duration: 1 },
    x: 0,
  },
  hidden: {
    y: 50,
    opacity: 0,
    scale: 1,
  },
};

const Social = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <section id="social" className="social">
      <div className="social__container">
        <motion.div
          className="social__title"
          variants={SocialVariant}
          ref={ref}
          initial="hidden"
          animate={control}
        >
          <div className="social__title-image">
            <img src={Socialtitle} alt="Social" />
          </div>
        </motion.div>

        <div className="redes__container">
          <motion.div
            className="redes"
            variants={spotifyVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://open.spotify.com/artist/1L0tJ2aCQ32yIioYxpfZAT"
              className="redes-image"
              target="_blank"
              rel="noreferrer"
            >
              <Spotify />
            </a>
          </motion.div>

          <motion.div
            className="redes"
            variants={youtubeVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://www.youtube.com/trolatv"
              className="redes-image"
              target="_blank"
              rel="noreferrer"
            >
              <Youtube />
            </a>
          </motion.div>

          <motion.div
            className="redes"
            variants={facebookVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://www.facebook.com/latrola/"
              className="redes-image"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
          </motion.div>

          <motion.div
            className="redes"
            variants={instagramVariant}
            ref={ref}
            initial="hidden"
            animate={control}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              href="https://www.instagram.com/latrolaoficial/"
              className="redes-image"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </a>
          </motion.div>
        </div>

        <div className="social__pleca">
          <motion.div
            className="track__redes"
            variants={marqueeVariants}
            animate={control}
          >
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/carcara.munoz"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Carcará&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrInstagram />
              <a
                className="marquee__redes-v"
                href="https://www.instagram.com/erick_arellano_troncoso"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Erick&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />

              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/gustrago.garfias"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Gustavo&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/carlotropo.cortazariano"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Charly&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/gary.espindola"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Gary&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/carcara.munoz"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Carcará&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrInstagram />
              <a
                className="marquee__redes-v"
                href="https://www.instagram.com/erick_arellano_troncoso"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Erick&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />

              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/gustrago.garfias"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Gustavo&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/carlotropo.cortazariano"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Charly&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/gary.espindola"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Gary&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/carcara.munoz"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Carcará&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrInstagram />
              <a
                className="marquee__redes-v"
                href="https://www.instagram.com/erick_arellano_troncoso"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Erick&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />

              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/gustrago.garfias"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Gustavo&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/carlotropo.cortazariano"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Charly&nbsp;&nbsp;&nbsp;
              </a>
            </span>
            <span className="marquee__redes">
              <GrFacebook />
              <a
                className="marquee__redes-v"
                href="https://www.facebook.com/gary.espindola"
                target="_blank"
                rel="noreferrer"
              >
                &nbsp;Gary&nbsp;&nbsp;&nbsp;
              </a>
            </span>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Social;
