import React from 'react'
import './nav.css'
import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="/#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>H</a>
      <a href="#bio" onClick={() => setActiveNav('#bio')} className={activeNav === '#bio' ? 'active' : ''}>B</a>
      <a href="#music" onClick={() => setActiveNav('#music')} className={activeNav === '#music' ? 'active' : ''}>D</a>
      <a href="#video" onClick={() => setActiveNav('#video')} className={activeNav === '#video' ? 'active' : ''}>V</a>
      <a href="#social" onClick={() => setActiveNav('#social')} className={activeNav === '#social' ? 'active' : ''}>R</a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>C</a>
    </nav>
  )
}

export default Nav