import React from "react";
import "./header.css";
import Logo from "./Logo";
import Cabeza from "./Cabeza";
import HeaderSocials from "./HeaderSocials";
import { HiOutlineMail } from "react-icons/hi";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <HeaderSocials />
        <div className="logo">
          <Logo />
        </div>
        <div className="cabeza">
          <Cabeza />
        </div>

        <a href="#contact" className="scroll__down">
          <HiOutlineMail />
        </a>
      </div>
    </header>
  );
};

export default Header;
